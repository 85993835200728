import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "antd";
import { Loader, ButtonWithModal } from "components";
import { actions as alertsActions } from "modules/alerts";
import { Link } from "react-router-dom";
import { actions as profileActions } from "modules/profile";
import { sortStrings, sortDates, dateFormat, withNumberingColumn } from "utils/utils";
import moment from "moment";
import RadioSelect from "./RadioSelect";

import "./alerts.scss";

export const Alerts = () => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => (state?.user?.roles ? state.user.roles : []));
  const user = useSelector((state) => state?.user);

  useEffect(() => {
    if (roles?.length === 1 && roles?.includes("teacher")) {
      dispatch(alertsActions.getAlerts(user?.profile?.id));
    } else {
      dispatch(alertsActions.getAlerts());
    }
    // eslint-disable-next-line
  }, []);

  const loading = useSelector((state) => state.alerts && state.alerts.loading);
  const alerts = useSelector((state) => state && state.alerts && state.alerts.alerts);
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);
  const [goingInto2MonthsPagination, setGoingInto2MonthsPagination] = useState(null);
  const [goingInto5MonthsPagination, setGoingInto5MonthsPagination] = useState(null);
  const [goingInto8MonthsPagination, setGoingInto8MonthsPagination] = useState(null);
  const [goingIntoFirstYearPagination, setGoingIntoFirstYearPagination] = useState(null);
  const [goingIntoFiveYearsPagination, setGoingIntoFiveYearsPagination] = useState(null);
  const [withoutLessonsPagination, setWithoutLessonsPagination] = useState(null);
  const [bdaysPagination, setBdaysPagination] = useState(null);
  const [anniversariesPagination, setAnniversariesPagination] = useState(null);
  const [TeacherAnniversariesPagination, setTeacherAnniversariesPagination] = useState(null);
  const [percentageTshirtPagination, setPercentageTshirtPagination] = useState(null);
  const [percentageKKTshirtPagination, setPercentageKKTshirtPagination] = useState(null);
  const [percentageQuestPagination, setPercentageQuestPagination] = useState(null);

  const alertsColumns = [
    {
      title: "Student",
      dataIndex: "studentName",
      key: "studentName",
      sorter: (a, b) => sortStrings(a, b, "studentName"),
      render: (title, student) => {
        return (
          <Link
            to={`/dashboard/${schoolId}/profile/${student.studentId}`}
            onClick={() => dispatch(profileActions.getProfile(student.studentId))}
          >
            {title}
          </Link>
        );
      },
    },
    {
      title: "Tarif",
      dataIndex: "tarif",
      key: "tarif",
      sorter: (a, b) => sortStrings(a, b, "tarif"),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Starting Date",
      dataIndex: "startingDate",
      key: "startingDate",
      sorter: (a, b) => sortDates(a, b, "startingDate"),
      render: (date) => <span>{date === null ? "-" : moment(date).format(dateFormat)}</span>,
    },
  ];

  const alertsBirthdaysColumns = [
    {
      title: "Student",
      dataIndex: "profileTitle",
      key: "profileTitle",
      sorter: (a, b) => sortStrings(a, b, "profileTitle"),
      render: (profileTitle, { profileDob, profileId }) => {
        return (
          <>
            <Link
              to={`/dashboard/${schoolId}/profile/${profileId}`}
              onClick={() => dispatch(profileActions.getProfile(profileId))}
            >
              {profileTitle}
            </Link>
            <span>{` - ${moment().diff(profileDob, "years")} years old`}</span>
          </>
        );
      },
    },
    {
      title: "Birthday",
      dataIndex: "profileDob",
      key: "profileDob",
      sorter: (a, b) => sortDates(a, b, "profileDob"),
      render: (profileDob) => <span>{profileDob === null ? "-" : moment(profileDob).format(dateFormat)}</span>,
    },
  ];

  const alertsAnniversariesColumns = [
    {
      title: "Student",
      dataIndex: "profileTitle",
      key: "profileTitle",
      sorter: (a, b) => sortStrings(a, b, "profileTitle"),
      render: (profileTitle, { profileYears, profileId }) => {
        return (
          <>
            <Link
              to={`/dashboard/${schoolId}/profile/${profileId}`}
              onClick={() => dispatch(profileActions.getProfile(profileId))}
            >
              {profileTitle}
            </Link>
            {profileYears > 0 ? <span>{` - ${profileYears} years in MMS`}</span> : null}
          </>
        );
      },
    },
    {
      title: "Starting Date",
      dataIndex: "profileStarted",
      key: "profileStarted",
      sorter: (a, b) => sortDates(a, b, "profileStarted"),
      render: (profileStarted) => (
        <span>{profileStarted === null ? "-" : moment(profileStarted).format(dateFormat)}</span>
      ),
    },
  ];

  const alertsTeacherAnniversariesColumns = [
    {
      title: "Teacher",
      dataIndex: "profileTitle",
      key: "profileTitle",
      sorter: (a, b) => sortStrings(a, b, "profileTitle"),
      render: (profileTitle, { profileYears, profileId }) => {
        return (
          <>
            <Link
              to={`/dashboard/${schoolId}/profile/${profileId}`}
              onClick={() => dispatch(profileActions.getProfile(profileId))}
            >
              {profileTitle}
            </Link>
            {profileYears > 0 ? <span>{` - ${profileYears} years in MMS`}</span> : null}
          </>
        );
      },
    },
    {
      title: "Starting Date",
      dataIndex: "profileStarted",
      key: "profileStarted",
      sorter: (a, b) => sortDates(a, b, "profileStarted"),
      render: (profileStarted) => (
        <span>{profileStarted === null ? "-" : moment(profileStarted).format(dateFormat)}</span>
      ),
    },
  ];

  const percentageTshirtColumns = [
    {
      title: "First name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => sortStrings(a, b, "first_name"),
      render: (text, { profile_id }) => {
        return (
          <>
            <Link
              to={`/dashboard/${schoolId}/profile/${profile_id}`}
              onClick={() => dispatch(profileActions.getProfile(profile_id))}
            >
              {text}
            </Link>
          </>
        );
      },
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => sortStrings(a, b, "last_name"),
      render: (text, { profile_id }) => {
        return (
          <>
            <Link
              to={`/dashboard/${schoolId}/profile/${profile_id}`}
              onClick={() => dispatch(profileActions.getProfile(profile_id))}
            >
              {text}
            </Link>
          </>
        );
      },
    },
    {
      title: "Contract",
      dataIndex: "contract",
      key: "contract",
      sorter: (a, b) => sortStrings(a, b, "last_name"),
      render: (text, { contract }) => <span>{contract?.tarif}</span>,
    },
    {
      title: "Instruments",
      dataIndex: "contract",
      key: "contract",
      sorter: (a, b) => sortStrings(a, b, "last_name"),
      render: (text, { contract }) => <span>{contract?.instruments}</span>,
    },
    {
      title: "T-shirt",
      dataIndex: "tshirt_voucher_status",
      key: "tshirt_voucher_status",
      sorter: (a, b) => sortStrings(a, b, "tshirt_voucher_status"),
      render: (text, { profile_id }) => {
        return <RadioSelect text={text} id={profile_id} />;
      },
      //   <Radio.Group
      //     buttonStyle="solid"
      //     value={text}
      //     onChange={() => {
      //       dispatch(
      //         formFieldsActions.editItem(
      //           { type: "profile", field_tshirt_voucher_status: text === "pending" ? "received" : "pending" },
      //           profile_id
      //         )
      //       );
      //     }}
      //   >
      //     <Radio.Button value="received">Yes</Radio.Button>
      //     <Radio.Button value="pending">No</Radio.Button>
      //   </Radio.Group>
      // ),
    },
  ];

  const percentageKKTshirtColumns = [
    {
      title: "First name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => sortStrings(a, b, "first_name"),
      render: (first_name) => <span>{first_name}</span>,
    },
    {
      title: "Last name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => sortStrings(a, b, "last_name"),
      render: (last_name) => <span>{last_name}</span>,
    },
    {
      title: "Parent name",
      dataIndex: "parent",
      key: "parent",
      sorter: (a, b) => sortStrings(a, b, "last_name"),
      render: (parent) => {
        return (
          <>
            <Link to={`/dashboard/klingKlong/parent/${parent?.id}`}>
              {parent?.first_name} {parent.last_name}
            </Link>
          </>
        );
      },
    },

    {
      title: "T-shirt",
      dataIndex: "received",
      key: "received",
      sorter: (a, b) => sortStrings(a, b, "received"),
      render: (text, { id }) => {
        return <RadioSelect text={text} id={id} isKKStudent={true} />;
      },
    },
  ];

  const percentageBooksColumns = [
    {
      title: "First name",
      dataIndex: "student",
      key: "student",
      sorter: (a, b) => sortStrings(a, b, "first_name"),
      render: (student) => {
        return (
          <>
            <Link
              to={`/dashboard/${schoolId}/profile/${student?.id}`}
              onClick={() => dispatch(profileActions.getProfile(student?.id))}
            >
              {student?.first_name}
            </Link>
          </>
        );
      },
    },
    {
      title: "Last name",
      dataIndex: "student",
      key: "student",
      sorter: (a, b) => sortStrings(a, b, "last_name"),
      render: (student) => {
        return (
          <>
            <Link
              to={`/dashboard/${schoolId}/profile/${student?.id}`}
              onClick={() => dispatch(profileActions.getProfile(student?.id))}
            >
              {student?.last_name}
            </Link>
          </>
        );
      },
    },
    {
      title: "Contract",
      dataIndex: "tarif",
      key: "tarif",
      sorter: (a, b) => sortStrings(a, b, "tarif"),
      render: (tarif) => <span>{tarif}</span>,
    },
    {
      title: "Instruments",
      dataIndex: "instrument",
      key: "instrument",
      sorter: (a, b) => sortStrings(a, b, "instrument"),
      render: (instrument) => <span>{instrument}</span>,
    },
    {
      title: "Books owned",
      dataIndex: "books",
      key: "books",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "T-shirt",
      dataIndex: "tshirt",
      key: "tshirt",
      sorter: (a, b) => sortStrings(a, b, "tshirt"),
      render: (text) => <span>{text}</span>,
    },
  ];

  const makeAlerts = (alerts) => {
    return Object.entries(alerts).map((item) => {
      if (item[0] === "going_into_2_months") {
        return (
          <div className={"alertsCard__row"} key={"going_into_2_months"}>
            <ButtonWithModal
              modalTitle={"Students Going into 2 months"}
              buttonTitle={` Student(s) going into 2 months`}
              buttonNumber={item?.[1].length || 0}
            >
              <Table
                bordered
                columns={withNumberingColumn(alertsColumns, goingInto2MonthsPagination)}
                onChange={(pagination) => {
                  setGoingInto2MonthsPagination(pagination);
                }}
                dataSource={item[1]}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }
      if (item[0] === "going_into_5_months") {
        return (
          <div className={"alertsCard__row"} key={"going_into_5_months"}>
            <ButtonWithModal
              modalTitle={"Students Going into 5 months"}
              buttonTitle={` Student(s) going into 5 months`}
              buttonNumber={item?.[1].length || 0}
            >
              <Table
                bordered
                columns={withNumberingColumn(alertsColumns, goingInto5MonthsPagination)}
                onChange={(pagination) => {
                  setGoingInto5MonthsPagination(pagination);
                }}
                dataSource={item[1]}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }
      if (item[0] === "going_into_8_months") {
        return (
          <div className={"alertsCard__row"} key={"going_into_8_months"}>
            <ButtonWithModal
              modalTitle={"Students Going into 8 months"}
              buttonTitle={` Student(s) going into 8 months`}
              buttonNumber={item?.[1].length || 0}
            >
              <Table
                bordered
                columns={withNumberingColumn(alertsColumns, goingInto8MonthsPagination)}
                onChange={(pagination) => {
                  setGoingInto8MonthsPagination(pagination);
                }}
                dataSource={item[1]}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }
      if (item[0] === "going_into_first_year") {
        return (
          <div className={"alertsCard__row"} key={"going_into_first_year"}>
            <ButtonWithModal
              modalTitle={"Students Going first year"}
              buttonTitle={` Student(s) going into first year`}
              buttonNumber={item?.[1].length || 0}
            >
              <Table
                bordered
                columns={withNumberingColumn(alertsColumns, goingIntoFirstYearPagination)}
                onChange={(pagination) => {
                  setGoingIntoFirstYearPagination(pagination);
                }}
                dataSource={item[1]}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>{" "}
          </div>
        );
      }
      if (item[0] === "going_into_five_years") {
        return (
          <div className={"alertsCard__row"} key={"going_into_five_years"}>
            <ButtonWithModal
              modalTitle={"Students Going into five years"}
              buttonTitle={` Student(s) going into five years`}
              buttonNumber={item?.[1].length || 0}
            >
              <Table
                bordered
                columns={withNumberingColumn(alertsColumns, goingIntoFiveYearsPagination)}
                onChange={(pagination) => {
                  setGoingIntoFiveYearsPagination(pagination);
                }}
                dataSource={item[1]}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }
      if (item[0] === "contracts_without_lessons") {
        return (
          <div className={"alertsCard__row"} key={"contracts_without_lessons"}>
            <ButtonWithModal
              modalTitle={"Contracts without lessons"}
              buttonTitle={`Contract(s) without lessons`}
              buttonNumber={item?.[1].length || 0}
            >
              <Table
                bordered
                columns={withNumberingColumn(alertsColumns, withoutLessonsPagination)}
                onChange={(pagination) => {
                  setWithoutLessonsPagination(pagination);
                }}
                dataSource={item[1]}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }
      if (item[0] === "students_dob_30_days") {
        return (
          <div className={"alertsCard__row"} key={"students_dob_30_days"}>
            <ButtonWithModal
              modalTitle={"Student birthdays"}
              buttonTitle={`Birthday(s) in the next 30 days`}
              buttonNumber={item?.[1].counter || 0}
            >
              <Table
                bordered
                columns={withNumberingColumn(alertsBirthdaysColumns, bdaysPagination)}
                onChange={(pagination) => {
                  setBdaysPagination(pagination);
                }}
                dataSource={item[1].profiles}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }
      if (item[0] === "students_anniversaries") {
        return (
          <div className={"alertsCard__row"} key={"students_anniversaries"}>
            <ButtonWithModal
              modalTitle={"Students celebrating in MMS"}
              buttonTitle={`Anniversaries in the next 30 days`}
              buttonNumber={item[1]?.profiles?.filter((item) => item.profileYears > 0).length || 0}
            >
              <Table
                bordered
                columns={withNumberingColumn(alertsAnniversariesColumns, anniversariesPagination)}
                onChange={(pagination) => {
                  setAnniversariesPagination(pagination);
                }}
                dataSource={item[1]?.profiles?.filter((item) => item.profileYears > 0)}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }
      if (item[0] === "teachers_anniversaries") {
        return (
          <div className={"alertsCard__row"} key={"teachers_anniversaries"}>
            <ButtonWithModal
              modalTitle={"Teachers celebrating in MMS"}
              buttonTitle={`Teacher Anniversaries`}
              buttonNumber={item[1]?.profiles?.length || 0}
            >
              <Table
                bordered
                columns={withNumberingColumn(alertsTeacherAnniversariesColumns, TeacherAnniversariesPagination)}
                onChange={(pagination) => {
                  setTeacherAnniversariesPagination(pagination);
                }}
                dataSource={item[1]?.profiles}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }
      if (item[0] === "percentage_received_tshirt") {
        return (
          <div className={"alertsCard__row"} key={"percentage_received_tshirt"}>
            <span className={"buttonWithModal__buttonNumber--simple"}>{item[1]?.percentage}</span>
            <ButtonWithModal
              modalTitle={"Students that have received t-shirt"}
              buttonTitle={`have received t-shirt`}
              isTshirtModal
            >
              <Table
                bordered
                columns={withNumberingColumn(percentageTshirtColumns, percentageTshirtPagination)}
                onChange={(pagination) => {
                  setPercentageTshirtPagination(pagination);
                }}
                dataSource={item[1]?.profiles}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }

      if (item[0] === "percentage_students_quest_books") {
        return (
          <div className={"alertsCard__row"} key={"percentage_students_quest_books"}>
            <span className={"buttonWithModal__buttonNumber--simple"}>{item[1].percentage}</span>
            <ButtonWithModal modalTitle={`Students that own Quest Books`} buttonTitle={`own Quest Books`}>
              <Table
                bordered
                columns={withNumberingColumn(percentageBooksColumns, percentageQuestPagination)}
                onChange={(pagination) => {
                  setPercentageQuestPagination(pagination);
                }}
                dataSource={item[1]?.profiles}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }

      if (item[0] === "percentage_kk_tshirt") {
        return (
          <div className={"alertsCard__row"} key={"percentage_kk_tshirt"}>
            <span className={"buttonWithModal__buttonNumber--simple"}>{item[1]?.percentage}</span>
            <ButtonWithModal
              modalTitle={"Students that have received their Kling Klong"}
              buttonTitle={`have received Kling Klong`}
              isTshirtModal
            >
              <Table
                bordered
                columns={withNumberingColumn(percentageKKTshirtColumns, percentageKKTshirtPagination)}
                onChange={(pagination) => {
                  setPercentageKKTshirtPagination(pagination);
                }}
                dataSource={item[1]?.profiles}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </ButtonWithModal>
          </div>
        );
      }

      return null;
    });
  };

  return !loading ? (
    <div className={"alertsCard"}>
      <div>{alerts && makeAlerts(alerts)}</div>
    </div>
  ) : (
    <Loader />
  );
};

export default Alerts;
