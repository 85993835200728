import React from "react";
import { Tag } from "antd";

export const ColorfulTextBlock = ({ text, status }) => {
  switch (status) {
    case "In Trial":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#c41d7f",
            background: "#fff0f6",
            border: "1px solid #ffadd2",
          }}
        >
          {text}
        </div>
      );
    case "In Process":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#000",
            background: "#fafafa",
            border: "1px solid #d9d9d9",
          }}
        >
          {text}
        </div>
      );

    case "Without Trial":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#1d39c4",
            background: "#f0f5ff",
            border: "1px solid #adc6ff",
          }}
        >
          {text}
        </div>
      );

    case "Signed":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#389e0d",
            background: "#f6ffed",
            border: "1px solid #b7eb8f",
          }}
        >
          {text}
        </div>
      );

    case "Archived - Not interested":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#cf1322",
            background: "#fff1f0",
            border: "1px solid #ffa39e",
          }}
        >
          {text}
        </div>
      );

    case "Archived - Data Entry Error":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#cf1322",
            background: "#fff1f0",
            border: "1px solid #ffa39e",
          }}
        >
          {text}
        </div>
      );

    case "Archived - Personal reasons":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#cf1322",
            background: "#fff1f0",
            border: "1px solid #ffa39e",
          }}
        >
          {text}
        </div>
      );

    case "Archived - Too Expensive":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#cf1322",
            background: "#fff1f0",
            border: "1px solid #ffa39e",
          }}
        >
          {text}
        </div>
      );

    case "Archived - Not satisfied":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#cf1322",
            background: "#fff1f0",
            border: "1px solid #ffa39e",
          }}
        >
          {text}
        </div>
      );

    case "Archived":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#cf1322",
            background: "#fff1f0",
            border: "1px solid #ffa39e",
          }}
        >
          {text}
        </div>
      );

    case "Follow up":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#d46b08",
            background: "#fff7e6",
            border: "1px solid #ffd591",
          }}
        >
          {text}
        </div>
      );

    case "ACTIVE":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#389e0d",
            background: "#f6ffed",
            border: "1px solid #b7eb8f",
          }}
        >
          {text}
        </div>
      );

    case "PAUSED":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#d46b08",
            background: "#fff7e6",
            border: "1px solid #ffd591",
          }}
        >
          {text}
        </div>
      );

    case "CANCELED":
      return (
        <div
          style={{
            fontSize: "16px",
            padding: "8px",
            color: "#cf1322",
            background: "#fff1f0",
            border: "1px solid #ffa39e",
          }}
        >
          {text}
        </div>
      );

    case "TO CANCEL":
      return (
        <Tag style={{ fontSize: "20px", padding: "8px" }} color="volcano">
          {text}
        </Tag>
      );

    case "COMPLETED":
      return (
        <Tag style={{ fontSize: "20px", padding: "8px" }} color="cyan">
          {text}
        </Tag>
      );

    case "FREEZE":
      return (
        <Tag style={{ fontSize: "20px", padding: "8px" }} color="cyan">
          {text}
        </Tag>
      );

    case "BLOCKED":
      return (
        <Tag style={{ fontSize: "20px", padding: "8px" }} color="red">
          {text}
        </Tag>
      );

    case "NO BILL":
      return (
        <Tag style={{ fontSize: "20px", padding: "8px" }} color="red">
          {text}
        </Tag>
      );

    case "MMS":
      return (
        <Tag style={{ fontSize: "20px", padding: "8px" }} color="#DD4B39">
          {text}
        </Tag>
      );

    case "KlingKlong":
      return (
        <Tag style={{ fontSize: "20px", padding: "8px" }} color="#F89406">
          {text}
        </Tag>
      );

    case "VibrA":
      return (
        <Tag style={{ fontSize: "20px", padding: "8px" }} color="#468847">
          {text}
        </Tag>
      );

    default:
      return <Tag style={{ fontSize: "20px", padding: "8px" }}>{text}</Tag>;
  }
};
export default ColorfulTextBlock;
