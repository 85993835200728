import React from "react";
import { useSelector } from "react-redux";
import { Table, Tabs } from "antd";
import { ColorfulTag } from "components";
import { CardChart } from "components";
import { PieChart, Pie, Legend, Cell } from "recharts";
import { colors } from "utils/config";
import { Link } from "react-router-dom";
import { sortStrings, withNumberingColumn } from "utils/utils";

const TeacherStudents = () => {
  const schoolId = useSelector((state) => state?.user?.selectedSchool?.id);
  const teacherStudents = useSelector((state) => state.teachersProfile && state.teachersProfile.teacherStudents);
  const teacherStudentsMapped = teacherStudents?.map((student, index) => {
    return {
      order: index,
      title: student.contractStudentFullName,
      status: student.contractStatus,
      instrument: student.instruments,
      tarif: student.contractTarif,
      id: student.contractStudentId,
      key: index,
    };
  });

  const { TabPane } = Tabs;

  const getCalculatedStudents = (data) => {
    let total = 0;
    data.map((studentStatus) => {
      total += studentStatus.value;
    });
    return total;
  };

  const teacherStudentsCol = [
    {
      title: "Student Name",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => sortStrings(a, b, "title"),
      render: (title, { id }) => {
        return <Link to={`/dashboard/${schoolId}/profile/${id}`}>{title}</Link>;
      },
    },

    {
      title: "Tarif",
      dataIndex: "tarif",
      key: "tarif",
      sorter: (a, b) => sortStrings(a, b, "tarif"),
      render: (tarif) => <span>{tarif}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => sortStrings(a, b, "status"),
      render: (status) => (
        <span>
          <ColorfulTag text={status} />
        </span>
      ),
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      key: "instrument",
      sorter: (a, b) => sortStrings(a, b, "instrument"),
      render: (instrument) => <span>{instrument}</span>,
    },
  ];
  const pieDataActive = [];
  const statusDataActive = [];
  const pieDataFormer = [];
  const statusDataFormer = [];
  teacherStudentsMapped &&
    teacherStudentsMapped.reduce(function (res, value) {
      if (!res[value.status]) {
        res[value.status] = { name: value.status, value: 0 };
        if (value.status !== "CANCELED") {
          pieDataActive.push(res[value.status]);
          statusDataActive.push(res[value.status]);
        }
        if (value.status === "CANCELED") {
          pieDataFormer.push(res[value.status]);
          statusDataFormer.push(res[value.status]);
        }
      }
      res[value.status].value++;

      return res;
    }, {});

  return (
    <>
      <Tabs className={"teacherTabs"} defaultActiveKey="1" type={"card"}>
        <TabPane tab={"Active"} key={"1"}>
          <CardChart
            cardHeight={"300px"}
            chartHeight={"250px"}
            chart={
              <PieChart>
                <Pie dataKey="value" data={pieDataActive} innerRadius={40} outerRadius={70} paddingAngle={5}>
                  {" "}
                  {pieDataActive?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            }
          />

          <div className={"stats"}>
            {statusDataActive?.map(({ name, value }) => {
              return name !== "CANCELED" ? (
                <div className={"stats__item"}>
                  {" "}
                  <span className={"stats__item__title"}>
                    <span className={"stats__item__title__number"}> {value} </span>
                    {name.toLowerCase()} Students{" "}
                  </span>{" "}
                  <span className={"stats__item__percentage"}>{`${(
                    (value / getCalculatedStudents(statusDataActive)) *
                    100
                  ).toFixed(1)} %`}</span>
                </div>
              ) : null;
            })}
          </div>

          <div className={"stats"}></div>
          <Table
            columns={withNumberingColumn(teacherStudentsCol)}
            dataSource={teacherStudentsMapped.filter((i) => i.status !== "CANCELED")}
            pagination={false}
          />
        </TabPane>
        <TabPane tab={"Former"} key={"2"}>
          <CardChart
            cardHeight={"300px"}
            chartHeight={"250px"}
            chart={
              <PieChart>
                <Pie dataKey="value" data={pieDataFormer} innerRadius={40} outerRadius={70} paddingAngle={5}>
                  {" "}
                  {pieDataFormer?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            }
          />

          <div className={"stats"}>
            {statusDataFormer?.map(({ name, value }) => {
              return name === "CANCELED" ? (
                <div className={"stats__item"}>
                  {" "}
                  <span className={"stats__item__title"}>
                    <span className={"stats__item__title__number"}> {value} </span>
                    {name.toLowerCase()} Students{" "}
                  </span>{" "}
                  <span className={"stats__item__percentage"}>{`${(
                    (value / getCalculatedStudents(statusDataFormer)) *
                    100
                  ).toFixed(1)} %`}</span>
                </div>
              ) : null;
            })}
          </div>

          <div className={"stats"}></div>
          <Table
            columns={withNumberingColumn(teacherStudentsCol)}
            dataSource={teacherStudentsMapped.filter((i) => i.status === "CANCELED")}
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default TeacherStudents;
