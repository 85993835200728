import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useLocation } from "react-router-dom";
import { Loader } from "components";
import moment from "moment";
import { CheckOutlined, CloseOutlined, GlobalOutlined } from "@ant-design/icons";

import "./studentLessonLogs.scss";

import { getStudentLessonLogs } from "services/studentLessonLogs";

export const StudentLessonLogs = ({ type }) => {
  const location = useLocation();

  const [studentLessonLogsData, setStudentLessonLogsData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const profileId = location.pathname.split("profile/").pop();
    setLoading(true);
    getStudentLessonLogs(profileId).then(({ data }) => {
      setLoading(false);
      setStudentLessonLogsData(data);
    });
    //eslint-disable-next-line
  }, []);

  const renderIconForPresence = (presence) => {
    switch (presence) {
      case "present":
        return <CheckOutlined style={{ color: "green" }} />;
      case "no":
        return <CloseOutlined style={{ color: "red" }} />;
      case "online":
        return <GlobalOutlined style={{ color: "#b99022" }} />;
    }
  };

  const columns = [
    {
      title: "Lesson Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date, lesson) => (
        <span>
          <div>
            {moment(start_date).format("DD/MM/yyyy HH:mm")} - {moment(lesson?.end_date).format("HH:mm")}{" "}
          </div>
        </span>
      ),
    },

    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacher",
      render: (teacher) => <div>{teacher}</div>,
    },

    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (notes) => <div>{notes}</div>,
    },

    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      render: (comments) => <div>{comments ? comments : " - "}</div>,
    },

    {
      title: "Presence",
      dataIndex: "presence",
      key: "presence",
      render: (presence) => <div style={{ textAlign: "center" }}>{renderIconForPresence(presence)}</div>,
    },
  ];

  return loading ? (
    <Loader />
  ) : (
    <div className={"StudentLessonLogs"}>
      <Table bordered columns={columns} dataSource={studentLessonLogsData} pagination={false} />
    </div>
  );
};

export default StudentLessonLogs;
